<template>
    <div id="guide">
        <div class="admin_main_block">
            <div class="user_temp_title">{{article[0].title}}</div>
            <div class="admin_table_main" v-html="article[0].content">
                <!-- <div class="page_main">
                    <div class="FAQ">
                        <h2 style="text-align: center;">
                            <span style="font-size: xx-large; background-color: rgb(255, 255, 255); color: rgb(194, 79, 74);">代发指南FAQ</span>
                        </h2>
                        <div><br></div>
                        <div>
                            <h3>
                                <ol>
                                    <li>
                                        <p>
                                            <span style="color: rgb(194, 79, 74);">1 {{$store.state.tenantInfo.company}}是干嘛的？</span><br><br>
                                            <span style="font-size: medium;">
                                                <span style="font-weight: normal;">
                                                    {{$store.state.tenantInfo.company}}是采购与发货一站式服务的专业礼品代发平台，例如电商补完单发货送洗衣粉，送礼物，采购、仓储、打包、发货这些环节我们都可以帮您完成。而且成本非常低，注册会员圆通快递全国（包括偏远地区）<br>自建仓储，海量礼品选择，百万级采购，礼品最低只要0.1元/单，业内最低
                                                </span>。<br>
                                            </span><br><br>
                                            <span style="font-weight: normal;">日均发单≥1000笔可直接联系客服申请内部特价。</span><br><br><br>
                                            <span style="color: rgb(194, 79, 74);">2 {{$store.state.tenantInfo.company}}的发货流程是怎么样的，我该怎么使用？</span><br><br>
                                            <span style="font-weight: normal;">具体操作步骤：登入网站<br>第一步：导出收件人。<br>你从你的电商平台（天猫、淘宝、京东、拼多多）后台导出订单（需要含有收货地址）<br></span><br>
                                            <span style="font-weight: normal;">第二步：导入收件人。<br>将收件人信息导入到我们的平台（我们系统会智能筛选您需要发货的订单）<br><br>第三步：选择赠品。<br>选择给客户赠送的礼品（系统会显示您产品的重量以及费用），确定好后下单<br><br>第四步：批量发货。</span><br><br>
                                            <span style="font-weight: normal;">18分钟内我们系统会优先给您对应订单的快递单号，可以直接用我们的工具给淘宝后台批量发货。仓库会再当天下午4点钟以前将货物打包发出。<br><br>步完成发货，不管您有多少订单。</span><br><br><br><br>
                                            <span style="color: rgb(194, 79, 74);">3  发货地址不一样，对我们商家安全吗？</span><br><br><span style="font-weight: normal;">我们可以非常肯定的告诉您，风险系数：0 ，我们和小二都确认过的，发货地址不一样没有任何问题，而且发货地址一直以来都不在稽查范围内。<br>而且<br>a、现在商家发货多仓库发货很普遍，后台地址是杭州，实际发货地址有北京上海广州比比皆是<br>b、做一件代发的商家， 上架100款产品，可能有30个发货地，也很正常所以发货地，这块，您完全不用担心，电商平台不可能对发货地做太多文章。目前仓储外包发达，很多中大型商家都会选择把货放在有价格优势的仓储公司发货，如果要拿发货地说事，这些店别开了。<br></span><br><br><br>
                                            <span style="color: rgb(194, 79, 74);">4 {{$store.state.tenantInfo.company}}这个平台安全吗？</span><br><br>
                                            <span style="font-weight: normal;">我们是一个真实的礼品站点，所有东西均为实物，并且只售真品，绝不售假货。快递是真实的圆通快递</span><br><br><br><br>
                                            <span style="color: rgb(194, 79, 74);">5 {{$store.state.tenantInfo.company}}物流多久会出来？发货速度怎么样？物流更新怎么样</span>？<br><br>
                                            <span style="font-weight: normal;">这个是真实的快递，不是空包，所有流程和您平常发货的是一样的。先得到快递单号，去后台发货，然后仓库打包发出。<br>当天16：30点前下的订单，当天会打包发货完成。超过16:30次日17:00前打包发货完成。请合理安排发货时间.<br>我们出单号速度是13分钟以内，如果超过13分钟未出单号，请联系在线客服查看原因。（主要原因是因为客户上传的文件数据有错误导致的）<br></span><br>
                                            <span style="color: rgb(194, 79, 74);">6 被买家投诉{{$store.state.tenantInfo.company}}可以提供底单吗？</span><br><br>
                                            <span style="font-weight: normal;">淘宝、天猫、拼多多=菜鸟面单、 京东=无界面单 所以如需申诉，不需要底单，后台直接获取即可。如特殊情况需要部分面单，联系客服，将会在24小时内提供回传。默认不提供底单。</span><br><br>
                                            <span style="color: rgb(194, 79, 74);">7  {{$store.state.tenantInfo.company}}有什么样的礼品选择？ 支持采购和代发吗？</span><br><br>
                                            <span style="font-weight: normal;">我们的礼品之后会分为3大类<br>1、非配重产品（例：垃圾袋、剥橙器、棉签、挂件等等）<br>2、配重类产品（例：我们的明星产品洗衣液、洗手液、洗洁精等，用户可以使用）<br>3、高端礼品(我们拥有强大的供应链，陆续会上例：数据线、音响、湿巾、餐巾纸、娃娃等各类高品质礼品）<br>以上几大类产品SKU会不断增加，您的选择会越来越多<br>支持采购和代发，如果您需要不同的礼品有两种方式可以实现：<br>1、您可以将您想要的礼品告诉我们，我们帮你采购 我们给您代发<br>2、 您自己有产品，可以入我们仓库， 我们帮您代发<br></span><br>
                                            <span style="color: rgb(194, 79, 74);">8 {{$store.state.tenantInfo.company}}的费用是怎么计算的？</span><br><br>
                                            <span style="font-weight: normal;">建议您看下网站后台，有成本价的显示。普通会员快递价格一般全国通票3.2元/单，快递费+人工操作费+场地租金+快递外包装，全包！。另外只剩礼品费用，具体价格，看您自己选择，我们有不同的产品，最低的只要1毛钱。<br></span><br>
                                            <span style="color: rgb(194, 79, 74);">9 为什么{{$store.state.tenantInfo.company}}的快递和礼品这么便宜？</span><br><br>
                                            <span style="font-weight: normal;">我们和仓储公司建立了战略合作关系，能拿到最低的价格，而且在商家身上我们基本是不赚利润，给您的快递价格不仅仅是快递价格，里面还包含了快递成本、人工打包成本、场地成本、包装袋成本。<br>礼品采购量的都是以百万级采购的，不管任何产品，我们都需要拿到最低的价格，才能给商家朋友们尽可能低的价格。</span><br> <br><br>
                                            <span style="color: rgb(194, 79, 74);">10 充值没到账怎么办？每次充值一定要填写备注吗？忘记备注怎么办？</span><br><br>
                                            <span style="font-weight: normal;">为了不耽误您的时间，请务必按要求充值，若不填写备注或进行其他错误操作将无法自动到账。<br>若忘填备注或填错备注导致未到账，请联系在线客服，或者提交工单，平台将会在24小时内帮您处理，您充的钱一分都不会少的。</span><br><br>
                                            <span style="color: rgb(194, 79, 74);">11 {{$store.state.tenantInfo.company}}发的什么快递，发货地址是哪里？</span><br><br>
                                            <span style="font-weight: normal;">{{$store.state.tenantInfo.company}}一直以最高物流标准来对待礼品代发。<br><br>跟我们建立有战略合作的有圆通、中通、韵达、申通等知名快递公司，过程中我们会根据用户即时反馈进行快递渠道的优化，确保优质的物流体验。<br><br>目前主要发的是圆通和中通，如有必要我们可以随时拓展其他知名快递渠道<br><br>发货地为：</span>
                                            <span style="font-size: large; font-weight: normal;">义乌圆通快递电商仓储物流中心</span>
                                        </p>
                                        <p>
                                            <br><span style="color: rgb(194, 79, 74);">12 出现货物丢失或者快递遗失怎么办？</span><br><br><br>
                                            <span style="font-weight: normal;">如出现这种情况可以在线提交申请查件，我们工作人员会与快递网点核实订单情况，如确定遗失，我们可在线申请补发，补发费用将由平台承担。</span><br><br>
                                            <span style="color: rgb(194, 79, 74);">13 什么样的货物在你们这边发会比较划算？</span><br><br>
                                            <span style="font-weight: normal;">具体根据您自己选择的产品而定，您在选择产品的过程中，系统同步会计算您的费用。但是请您相信跟您自己或者找其他渠道发货相比，我们这里都是最便宜的。</span><br><br><br>
                                            <span style="color: rgb(194, 79, 74);">14 如果出现货发错了，物流可以截回吗？下单任务可以撤回吗？买错了，可以退款吗？</span><br><br>
                                            <span style="font-weight: normal;">下单后5分钟内可以到任务管理里面撤销任务，超过时效则不支持任务撤销，如物流已发出需要截回，物流成本将由商家自行承担。<br>5分钟内的任务可以申请撤回，超过5分钟不可撤回退款。</span><br><br>
                                            <span style="color: rgb(194, 79, 74);">15 发货面单写的什么内容，有没有商品信息？重量怎么配？</span><br><br>
                                            <span style="font-weight: normal;">面单上不填写商品信息。<br>面单上也不显示重量，但是快递系统里面的称重都是按照您实际发货货物的重量称重的<br>重量为真实重量，您可以根据需要选择赠送对应重量的礼品，如洗衣液，矿泉水等，需要多少重量就匹配多少重量。<br></span><br>
                                            <span style="color: rgb(194, 79, 74);">16 充值后不使用了，余额是否可以提现？</span><br><br>
                                            <span style="font-weight: normal;">可以。找在线客服，人工处理提现即可，是您的钱一分都不会少。</span><br><br>
                                            <span style="color: rgb(194, 79, 74);">17 你们的产品是真的吗？质量如何？</span><br><br>
                                            <span style="font-weight: normal;">我们平台只做真品，坚决不做假货，不管什么产品，绝对不涉及违法，请放心使用<br>不同的礼品质量不一样，但是平台没有假货。具体要看是否满足您的需求，您可以申请样品，拿到手里试试看。</span><br><br>
                                            <span style="color: rgb(194, 79, 74);">18  一个账号能发多个店铺吗？</span><br><br>
                                            <span style="font-weight: normal;">{{$store.state.tenantInfo.company}}对发货店铺没有做要求，可以多个店铺一起使用。</span><br><br>
                                            <span style="color: rgb(194, 79, 74);">19 {{$store.state.tenantInfo.company}}支持批量发货吗？</span><br><br><br>
                                            <span style="font-weight: normal;">我们平台很多都是大卖家和工作室，这一块有帮您考虑的，任务边上有一个【批量发货】按钮，推荐使用批量发货工具实现一键发</span>
                                        </p>
                                    </li>
                                </ol>
                            </h3>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            article:{
                title:'',
                conent:'',
            },
        }
    },
    created(){
        this.getArticleList();
        // this.IsPC();
    },
    mounted(){},
    watch: {},
    methods: {
        IsPC(){  
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
            var flag = true;  
            for (var v = 0; v < Agents.length; v++) {  
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
            }  
            if(!flag){
                this.$router.push('/m_guide')
            }
        },
        //获取最新一条代发指南
        getArticleList(){
            this.$request.post({
                url:'/ReportApi/business/newlists',
                params:{
                    pageNo:1,
                    pageSize:1,
                    type:6,
                    isConent:1,
                },
                success: res => {
                    if(res[0].length>0){
                        this.article = res[0];
                        console.log(this.article)
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#guide{
    .admin_main_block{
        border-radius: 4px;
        min-height: 855px;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        ol{
            list-style: none;
        }
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }
}
</style>